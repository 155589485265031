
import ufoAnimation from '~/static/animations/ufo.json'

export default {
    data(){
        return {
            ufoAnimation: {
                animationData: ufoAnimation,
                animationSpeed: 1,
                autoplay: true,
                renderer: 'svg',
                loop: true
            },
        }
    },
}
